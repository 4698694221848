import React, {useContext, useEffect, useState} from 'react';
import MyInput from '../UI/input/MyInput';
import cl from './styles/UserProfile.module.css'
import MyButton from "../UI/button/MyButton";
import MyModal from "../UI/modal/MyModal";
import MySelect from "../UI/select/MySelect";
import InternationalPhone from "../UI/phoneValidation/InternationalPhone";
import UserService from "../services/UserService";
import { Context } from "../index";
import cfg from "../cfg";
import {useFetching} from "../hooks/useFetching";
import Loader from "../UI/loader/Loader";
import eye_open from "../static/images/open.png";
import eye_close from "../static/images/close.png";


const UserProfile = () => {
    const [passOpen, setPassOpen] = useState(false)
    const [profileData, setProfileData] = useState({
        age: 0,
        city: '',
        description: '',
        diagnosis: '',
        email: '',
        id: '',
        name: '',
        phone: '',
        priority_link: '',
        sex: 'Не указан',
        surname: '',
        telegram_id: '',
    })
    const priorityLinkSelectData = [
        {name: 'Телефон', value: 'phone'},
        {name: 'Telegram', value: 'telegram'}
    ]
    const { store } = useContext(Context)
    const [modalActive, setModalActive] = useState(false)
    const [backendResponse, setBackendResponse] = useState('')
    const [credentials, setCredentials] = useState({
        new_password: '',
        repeat_password: '',
        old_password: ''
    })

    const [fetchProfile, profileIsLoading, profileError] = useFetching(
        async () => {
            const responseProfile = await UserService.profileByUserID(store.userID)
            // console.log(responseProfile)
            setProfileData(responseProfile)
        })

    useEffect(() => {
        fetchProfile()
    }, []);

    const onSubmitProfile = async (event) => {
        event.preventDefault()
        try {
            if (profileData.age) {
                const response = await UserService.updateProfile(profileData)
                setBackendResponse(response.data)
                // localStorage.setItem('profile', JSON.stringify(profileData))
                setModalActive(true)
                setTimeout(() => {
                    setModalActive(false)
                }, 2000)
            }
            else {
                setBackendResponse('Укажите свой возраст')
                setModalActive(true)
            }
        } catch (error) {
            setBackendResponse(error.response.data.detail)
            setModalActive(true)
        }
    }

    const onSubmitCredentials = async (event) => {
        event.preventDefault()
        try {
            const response = await UserService.changePassword({...credentials,
                email: profileData.email})
            setBackendResponse(response.data);
            setModalActive(true);
            setTimeout(() => {
                setModalActive(false);
                setCredentials({
                    ...credentials,
                    new_password: '',
                    repeat_password: '',
                    old_password: ''
                })
            }, 2000);

        } catch (error) {
            setBackendResponse(error.response.data.detail)
            setModalActive(true)
        }
    }

    const setPhone = (phone) => {
        setProfileData({ ...profileData, phone: phone });
    }


    const onChangeProfile = (label, event) => {
        // eslint-disable-next-line default-case
        switch (label) {
            case 'name':
                setProfileData({ ...profileData, name: event.target.value });
                break;
            case 'surname':
                setProfileData({ ...profileData, surname: event.target.value });
                break;
            case 'age':
                setProfileData({ ...profileData, age: event.target.value });
                break;
            case 'sex':
                setProfileData({ ...profileData, sex: event });
                break;
            case 'priority_link':
                setProfileData({ ...profileData, priority_link: event });
                break;
            case 'telegram_id':
                setProfileData({ ...profileData, telegram_id: event.target.value });
                break;
            case 'city':
                setProfileData({ ...profileData, city: event.target.value });
                break;
            case 'diagnosis':
                setProfileData({ ...profileData, diagnosis: event.target.value });
                break;
            case 'description':
                setProfileData({ ...profileData, description: event.target.value });
                break;
        }
    }

    const onChangeCredentials = (label, event) => {
        // eslint-disable-next-line default-case
        switch (label) {
            case 'new_password':
                setCredentials({ ...credentials, new_password: event.target.value });
                break;
            case 'repeat_password':
                setCredentials({ ...credentials, repeat_password: event.target.value });
                break;
            case 'old_password':
                setCredentials({ ...credentials, old_password: event.target.value });
                break;
        }
    }

    return (
        <div>
            <div className={store.isVerified ? cl.invisible : cl.verify_modal}>
                Спасибо, что присоединились к нашему фонду.
                В ближайшее время мы верифицируем ваш аккаунт,
                тогда вам откроется доступ к нашим помогающим программам.
                Уведомление о верификации придет вам на указанный при регистрации e-mail.
                Если вы ждёте верификацию уже несколько дней,
                напишите нашему администратору в
                <a
                style={{color: "whitesmoke", backgroundColor: "transparent"}}
                target="_blank"
                rel="noreferrer"
                href="https://t.me/eq_tg_bot"> telegram.</a>
            </div>

            {profileIsLoading
                ? <div><Loader/></div>
                : <form onSubmit={onSubmitProfile}>
                    <ul>
                        <div className={cl.content}>

                            <li>
                                <p>Имя</p>
                                <MyInput
                                    id='name'
                                    type='text'
                                    value={profileData.name}
                                    onChange={(event) => {
                                        onChangeProfile('name', event)
                                    }}>
                                </MyInput>
                            </li>

                            <li>
                                <p>Фамилия</p>
                                <MyInput
                                    id='surname'
                                    type='text'
                                    value={profileData.surname}
                                    onChange={(event) => {
                                        onChangeProfile('surname', event)
                                    }}>
                                </MyInput>
                            </li>

                            <li>
                                <p>Возраст</p>
                                <MyInput
                                    id='age'
                                    type='number'
                                    value={profileData.age === null ? '' : profileData.age}
                                    placeholder='Укажите возраст'
                                    onChange={(event) => {
                                        onChangeProfile('age', event)
                                    }}>
                                </MyInput>
                            </li>

                            <li>
                                <p>Пол</p>
                                <MySelect
                                    id='sex'
                                    value={profileData.sex}
                                    onChange={(event) => {
                                        onChangeProfile('sex', event)
                                    }}
                                    defaultValue={profileData.sex}
                                    options={[
                                        {name: 'Не указан', value: 'Не указан'},
                                        {name: 'Мужской', value: 'Мужской'},
                                        {name: 'Женский', value: 'Женский'}
                                    ]}
                                />
                            </li>

                            <li>
                                <p>Город</p>
                                <MyInput
                                    id='city'
                                    type='text'
                                    value={profileData.city}
                                    placeholder='Укажите город'
                                    onChange={(event) => {
                                        onChangeProfile('city', event)
                                    }}>
                                </MyInput>
                            </li>

                            <li>
                                <p>Телефон</p>
                                <div className={cl.phone}>
                                    <InternationalPhone
                                        value={profileData.phone}
                                        change={setPhone}
                                    />
                                </div>
                            </li>

                            <li>
                                <p>Telegram</p>
                                <MyInput
                                    id='tg'
                                    type='text'
                                    value={profileData.telegram_id}
                                    placeholder='Ваш telegram ID (например, @myname)'
                                    onChange={(event) => {
                                        onChangeProfile('telegram_id', event)
                                    }}>
                                </MyInput>
                            </li>

                            <li>
                                <p>Диагноз</p>
                                <MyInput
                                    id='diagnosis'
                                    type='text'
                                    value={profileData.diagnosis}
                                    placeholder='Укажите диагноз'
                                    onChange={(event) => {
                                        onChangeProfile('diagnosis', event)
                                    }}>
                                </MyInput>
                            </li>

                            <li>
                                <p>О себе</p>
                                <textarea
                                    id='description'
                                    value={profileData.description ? profileData.description : ''}
                                    className={cl.txt_area}
                                    placeholder='Максимум 500 символов'
                                    onChange={(event) => {
                                        onChangeProfile('description', event)
                                    }
                                    }
                                />
                            </li>

                            <li>
                                <div
                                    className={store.userRoleID === cfg.consultantID ? cl.priority_link : cl.invisible}>
                                    <p>Канал связи</p>
                                    <MySelect
                                        id='priorityLink'
                                        defaultValue='Контакт для клиентов'
                                        options={priorityLinkSelectData}
                                        value={profileData.priority_link ? profileData.priority_link : 'phone'}
                                        onChange={(event) => {
                                            onChangeProfile('priority_link', event)
                                        }
                                        }
                                    />
                                </div>
                            </li>

                            <div className={cl.btn}>
                                <MyButton
                                    type='submit'
                                >
                                    Изменить данные
                                </MyButton>
                            </div>
                            <div className={cl.my_modal}>
                                <MyModal
                                    active={modalActive}
                                    setActive={setModalActive}
                                >{backendResponse}
                                </MyModal>
                            </div>
                        </div>
                    </ul>
                </form>
            }

            <form onSubmit={onSubmitCredentials}>
                <ul>
                    <div className={cl.content} style={{marginTop: 15}}>
                        <div className={cl.pass_form}>
                            <li>
                                <div className={cl.pass}>
                                    <MyInput
                                        type={passOpen ? 'text' : 'password'}
                                        className={cl.input_image}
                                        id='oldPass'
                                        value={credentials.new_password}
                                        placeholder='Введите новый пароль'
                                        onChange={(event) => {
                                            onChangeCredentials('new_password', event)
                                        }}>
                                    </MyInput>
                                    <button
                                        type={"button"}
                                        onClick={() => {
                                            setPassOpen(!passOpen)
                                        }
                                        }
                                    >
                                        <img src={
                                            passOpen
                                                ? eye_open
                                                : eye_close}
                                             alt=""/>
                                    </button>
                                </div>

                            </li>
                            <li>
                                <MyInput
                                    type={passOpen ? 'text' : 'password'}
                                    id='newPass'
                                    value={credentials.repeat_password}
                                    placeholder='Введите новый пароль еще раз'
                                    onChange={(event) => {
                                        onChangeCredentials('repeat_password', event)
                                    }}>
                                </MyInput>
                            </li>
                            <li>
                                <MyInput
                                    type={passOpen ? 'text' : 'password'}
                                    id='repeatPass'
                                    value={credentials.old_password}
                                    placeholder='Введите старый пароль'
                                    onChange={(event) => {
                                        onChangeCredentials('old_password', event)
                                    }}>
                                </MyInput>
                            </li>
                        </div>
                        <div className={cl.btn}>
                            <MyButton
                                type='submit'
                            >
                                Изменить пароль
                            </MyButton>
                        </div>

                    </div>
                </ul>
            </form>
        </div>
    );
};
export default UserProfile;

