import React, { useState } from 'react';
import MyInput from "../UI/input/MyInput";
import MyButton from "../UI/button/MyButton";
import MyModal from "../UI/modal/MyModal";
import cl from './styles/ForgotPassword.module.css'
import UserService from "../services/UserService";
import Loader from "../UI/loader/Loader";

const ForgotPassword = () => {
    const [emailForm, setEmailForm] = useState({
        email: ''
    })
    const [isLoading, setIsLoading] = useState(false)
    const [modalActive, setModalActive] = useState(false)
    const [backendResponse, setBackendResponse] = useState('')
    const onSubmitHandler = async (event) => {
        event.preventDefault()
        setIsLoading(true)
        try {
            const response = await UserService.forgotPassword(emailForm)
            setBackendResponse(response.data)
            setEmailForm({ ...emailForm, email: '' })
            document.getElementById('email').value = ''
        } catch (error) {
            setBackendResponse(error.response.data.detail)
        } finally {
            setIsLoading(false)
            setModalActive(true)
        }
    }
    return (
        <div className={cl.content}>
            <form onSubmit={onSubmitHandler}>
                <div className={cl.body}>
                    <h2>Сброс пароля</h2>
                    <MyInput
                        id='email'
                        style={{width: '300px'}}
                        placeholder='Введите свой email'
                        onChange={(event) => {
                            setEmailForm({ ...emailForm, email: event.target.value })
                        }} />
                    {isLoading
                        ? <div><Loader/></div>
                        : <MyModal
                            active={modalActive}
                            setActive={setModalActive}
                        >{backendResponse}
                        </MyModal>
                    }

                    <MyButton type='submit'>Выполнить</MyButton>
                </div>
            </form>
        </div>

    );
};

export default ForgotPassword;